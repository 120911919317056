.dashboard {
    background-color: #f1f6fe;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    gap: 2rem;

    $parent: &;

    ul,
    li {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__logo {
        width: 224px;
        margin-bottom: 3rem;
        padding-top: 2rem;
        align-self: center;
        transition: all ease-out 200ms;
        overflow: hidden;
        min-height: 118px;

        img {
            width: 224px;
            height: 86px;
        }
    }

    &__menu {
        width: 280px;
        min-width: 280px;
        max-width: 280px;
        background-color: #ffffff;
        overflow: auto;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        transition: all ease-out 200ms;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        overflow: hidden;
        overflow-y: auto;

        span {
            opacity: 1;
            transition: all ease-out 200ms;
        }

        &-resize {
            padding: 1rem;
            width: 100%;
            transition: all ease-out 200ms;

            &-icon {
                width: 100%;
                background-color: #f2f2f2;
                border-radius: 1.5rem;
                padding: 0.5rem;

                svg {
                    transition: all ease-out 200ms;
                }

                &:hover {
                    background-color: #f0ecfe;
                    cursor: pointer;
                }
            }
        }

        &::-webkit-scrollbar {
            display: none;
        }

        &-list {
            min-width: 280px;
            list-style: none;
            padding: 0;
            margin: 0;
            width: 100%;

            > li {
                border-top: 1px solid #efefef;
                padding: 0.5rem 0;
            }

            &-headline {
                text-transform: uppercase;
                padding: 0.8rem 1rem;
                font-size: 0.7rem;
                font-weight: 700;
                transition: all ease-out 200ms;
            }
        }

        &-link {
            padding: 0.8rem 1rem !important;
            text-transform: capitalize;
            font-size: 1rem;
            font-weight: 400;
            display: block;
            color: #000;
            position: relative;
            transition: all ease-out 200ms;

            div {
                position: relative;
                z-index: 2;
            }

            &-active::after,
            &::after {
                background-color: #dfe8f8;
                height: 100%;
                border: none;
                bottom: 0;
                z-index: 1;
            }

            &:hover,
            &-active {
                color: #1534de;
                cursor: pointer;
                text-decoration: none;

                &::after {
                    border: none;
                    bottom: 0;
                    width: 100%;
                }
            }

            svg {
                margin-right: 0.5rem;
            }
        }

        &--icons {
            width: 100px;
            max-width: 100x;
            min-width: 100px;

            .dashboard {
                &__menu {
                    &-link {
                        padding-left: 2.3rem !important;
                    }

                    &-list-headline {
                        text-align: center;
                        width: 100px;
                    }

                    &-resize {
                        width: 74px;
                        align-self: center;

                        &-icon svg {
                            transform: rotate(180deg);
                        }
                    }
                }

                &__logo {
                    width: 60px;
                }
            }

            span {
                opacity: 0;
            }
        }
    }

    &__container {
        background-color: #fff;
        border-radius: 0.5rem;
        padding: 1rem;
        box-shadow: 0 0 5px 5px #eee;
        margin: 1rem 0.3rem;
    }

    > section {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding-right: 2rem;
        overflow: hidden;
        overflow-y: auto;

        > header {
            padding: 0.5rem 1rem;
            background-color: #fff;
            margin-top: 2rem;
            border-radius: 0.5rem;

            a {
                color: #445eed;

                &::after {
                    display: none;
                }

                &:hover {
                    color: #000;
                    ::after {
                        display: none;
                    }
                }
            }
        }
    }
}
